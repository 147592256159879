import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

export default class Gallery {
    constructor() {
        const wrapper = document.querySelectorAll('.walk-it-gallery--swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const WalkGallerySwiper = new Swiper('.walk-swiper', {

                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },

                slidesPerView: 1,
                spaceBetween: 20,

                modules: [ Navigation ],

            })
        });

    }
}