export default class MobileFAQs {
    constructor() {
        const faqTriggers = document.querySelectorAll('.faq-tab-mobile');
        const faqWrappers = document.querySelectorAll<HTMLElement>('.faq-mobile-accordion-results');

        this.hideAllFAQs(faqWrappers);

        faqTriggers.forEach(item => {
            item.addEventListener('click', () => {
                this.hideAllFAQs(faqWrappers);
                this.displayFAQ(item);
            });
        });
    }

    hideAllFAQs(faqWrappers: NodeListOf<HTMLElement>) {
        faqWrappers.forEach(element => {
            element.style.display = 'none';
        });
    }

    displayFAQ(item: Element) {
        const titleText = item.querySelector('.elementor-icon-box-title > span');
        if (titleText) {
            const cleanedText = titleText.textContent!.replace(/&/g, '').replace(/\s+/g, '-').toLowerCase();
            const queryText = 'faq-mobile' + cleanedText + 'results';
            console.log(queryText, 'a query text')
            const queryElement = document.getElementById(queryText);
            if (queryElement) {
                queryElement.style.display = 'block';
            } else {
                console.error('FAQ element not found:', queryText);
            }
        }
    }
}
