export default function () {
    const hamburger = document.getElementById('hamburger');
    const header = document.getElementById('site-header');

    if (!hamburger) {
        return;
    }

    if (!header) {
        return;
    }

    const menu = document.getElementById('mega-menu-primary_navigation'),
        overlay = document.getElementById('menu-overlay');

    const close = () => {
        menu?.classList.remove('is-active');
        hamburger.classList.remove('is-active');
        overlay?.classList.remove('is-active');
        document.documentElement.classList.remove('has-menu-open');
    };

    hamburger.addEventListener('click', () => {
        menu?.classList.toggle('is-active');
        hamburger.classList.toggle('is-active');
        overlay?.classList.toggle('is-active');
        document.documentElement.classList.toggle('has-menu-open');
    });

    window.addEventListener('scroll', () => {
        const y = window.scrollY;
        if (y > 400 && !header.classList.contains('is-fixed')) {
            header.classList.add('is-fixed');
            hamburger?.classList.add('is-fixed');
        } else if (y < 400) {
            header.classList.remove('is-fixed');
            hamburger?.classList.remove('is-fixed');
        }
    });
}