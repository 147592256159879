import '@styles/main.scss';
import menu from './components/menu';
import FAQTabs from './components/FAQTabs';
import EventAccordion from './components/EventAccordion';
import WalkItStepAccordion from './components/WalkItStepAccordion';
import Gallery from './components/Gallery';
import PaddleAccordion from './components/PaddleAccordion';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import EventGallery from './components/EventGallery';
import AppreciateAccordion from './components/AppreciateAccordion';
import CycleAccordion from './components/CycleAccordion';
import MobileFAQs from './components/mobile-faqs';

class App {
    constructor() {
        menu();
        new FAQTabs();
        new EventAccordion();
        new WalkItStepAccordion();
        new Gallery();
        new EventGallery();
        new PaddleAccordion();
        new AppreciateAccordion();
        new CycleAccordion();
        new MobileFAQs();
        this.searchForm();
    }

    searchForm() {
        const trigger = document.querySelector('.canal-search-link')
        const overlay = document.getElementById('search-overlay')
        const form = document.querySelector('.search-form');

        if(form === null) return;
        if(overlay === null) return;
        if(trigger === null) return;

        trigger.addEventListener('click', e => {
            e.preventDefault();

            overlay.classList.remove('is-hidden');
        })    

        overlay.addEventListener('click', () => overlay.classList.add('is-hidden'));

        form.addEventListener('click', e => e.stopPropagation());
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
