export default class FAQTabs {
    constructor() {
       const nav = document.querySelectorAll('.faq-tab');
       const tabs = document.querySelectorAll('.single-faq-wrapper');
       let activeTopic = '';

       // Reset tabs on new click function
       const closeAll = () => {
            nav.forEach(item => {
                item.classList.remove('is-active');
                item.setAttribute('aria-selected', 'false');
            })

            tabs.forEach(item => {
                item.classList.remove('is-active');
            })
        }

        nav.forEach(item => {
            item.addEventListener('click', () => {
                closeAll();

                item.classList.add('is-active');
                item.setAttribute('aria-selected', 'true');

                activeTopic = item.id.replace('faq-tab--', '');

                tabs.forEach(tab => {
                    if (tab.classList.contains(activeTopic)) {
                        tab.classList.add('is-active');
                    }
                });
            })
        })

        this.openWalkFAQ();

        // Accordion open content and close
        const accordionToggle = document.querySelectorAll(".accordion-toggle");
        const accordionToggleMobile = document.querySelectorAll(".accordion-toggle-mobile");
        const wrapper = document.querySelectorAll('.single-faq-wrapper');

        wrapper.forEach(item => {
            item.addEventListener('click', () => {
                const isItemOpen = item.classList.contains("open");

                wrapper.forEach(item => item.classList.remove('open'));

                if (!isItemOpen) {
                    item.classList.add("open");
                }
            })
        })

        accordionToggle.forEach(item =>
            item.addEventListener("click", () => {
                const isItemOpen = item.classList.contains("open");
        
                accordionToggle.forEach(button => button.classList.remove("open"));
                accordionToggle.forEach(button => button.textContent = 'Read more');
        
                if (!isItemOpen) {
                    item.classList.add("open");
                    item.textContent = 'Close';

                }
            })
        );
    }

    //Set walk it tab active on page load
    openWalkFAQ() {
        const walkFAQ = document.querySelectorAll('.walk-it');

        if(walkFAQ.length > 0) {
            walkFAQ.forEach(item => {
                item.classList.add('is-active');
            })
        }
    }
}