export default class WalkItStepAccordion {
    constructor() {
        const accordionToggle = document.querySelectorAll(".walk-accordion-toggle");
        const wrapper = document.querySelectorAll('.single-walk-it-wrapper');

        wrapper.forEach(item => {
            item.addEventListener('click', () => {
                const isItemOpen = item.classList.contains("open");

                wrapper.forEach(item => item.classList.remove('open'));

                if (!isItemOpen) {
                    item.classList.add("open");
                }
            })
        });

        accordionToggle.forEach(item =>
            item.addEventListener("click", () => {
                const isItemOpen = item.classList.contains("open");
        
                accordionToggle.forEach(button => button.classList.remove("open"));
                accordionToggle.forEach(button => button.textContent = 'Read more');
        
                if (!isItemOpen) {
                    item.classList.add("open");
                    item.textContent = 'Close';

                }
            })
        );
    }
}